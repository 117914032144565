import { capitalize, isSonarUrl } from "../helpers/utils";
import { SONAR_API_KEY, SONAR_AUTH0_PASSWORD, SONAR_AUTH0_PIN, SONAR_AUTH0_URL, SONAR_AUTH0_USERNAME } from "../helpers/env";

export const getToken = async () => {
   try {
      const url = `${SONAR_AUTH0_URL}/token`;
      const body = {
         UserName: SONAR_AUTH0_USERNAME,
         Password: SONAR_AUTH0_PASSWORD,
         Pin: SONAR_AUTH0_PIN
      };
      const params = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(body)
      }

      if (isSonarUrl(url)) {
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const token = await response.json();

      return token;
   }
   catch (error) {
      throw error
   }
}

export const refreshToken = async (refreshToken) => {
   try {
      if (!refreshToken) {
         return null;
      }

      const url = `${SONAR_AUTH0_URL}/refreshToken/${refreshToken}`;
      const params = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      if (isSonarUrl(url)) {
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const token = await response.json();

      return token;
   }
   catch (error) {
      throw error
   }
}

export const getUserById = async (userId, token) => {
   try {
      const url = `${SONAR_AUTH0_URL}/getUserById/${userId}`;
      const params = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json'
         }
      }

      if (token && isSonarUrl(url)) {
         params.headers["Authorization"] = `Bearer ${token}`;
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const foundUser = await response.json();

      const id = foundUser.UserId.toString();
      const name = foundUser.GivenName?.split(' ').map(item => item.trim()).filter(item => item) ?? []
      const familyName = capitalize(foundUser.FamilyName ?? '').trim();
      const firstName = capitalize(name[0] ?? '').trim();
      const givenName = name.map(item => capitalize(item)).join(' ').trim();
      const shortName = `${firstName} ${familyName}`;
      const fullName = `${givenName} ${familyName}`.trim();
      const initials = `${firstName.charAt(0)}${familyName.charAt(0)}`.trim();

      const user = {
         id,
         firstName,
         familyName,
         givenName,
         shortName,
         fullName,
         initials,
      }

      return user;
   }
   catch (error) {
      throw error
   }
}