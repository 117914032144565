export const GetTotalVaccinationsByServiceId = async (serviceId) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard6?serviceId=${serviceId}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;

   } catch (error) {
      return null;
   }
}

export const GetActivityByAreaAndServiceId = async (serviceId) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard1?serviceId=${serviceId}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;

   } catch (error) {
      return null;
   }
}

export const GetActivityByStpAndServiceId = async (serviceId, stp) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard2?serviceId=${serviceId}&stp=${stp}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;

   } catch (error) {
      return null;
   }
}

export const GetDailyVaccinationsByServiceId = async (serviceId, month, year) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard4?serviceId=${serviceId}&month=${month}&year=${year}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;

   } catch (error) {
      return null;
   }
}

export const GetInclusionsByServceIdAndStp = async (serviceId, stp) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard3?serviceId=${serviceId}&stp=${stp}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;

   } catch (error) {
      return null;
   }
}

export const GetMonthlyVaccinationsByServiceId = async (serviceId) => {
   try {

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}flu/getDashBoard5?serviceId=${serviceId}`
      const fecthParams = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json',
         }
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();
      return data;


   } catch (error) {
      return null;
   }
}
