import AuthProvider from "../../helpers/context/authContext/authCtx";
import VideoCallProvider from "../../helpers/context/videoCallContext/videoCallCtx";
import VideoCallTerms from "./videoCallTerms";
import VideoCallWrapper from "./videoCallWrapper";

const VideoCall = () => {
   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container p-4 text-center">
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                     <h1 className="display-1 mb-3">
                        Video Consultation
                     </h1>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container p-4">
               <AuthProvider>
                  <VideoCallProvider>
                     <VideoCallWrapper />
                     <VideoCallTerms />
                  </VideoCallProvider>
               </AuthProvider>
            </div>
         </section>
      </>
   );
};

export default VideoCall;
