import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CheckSquare, Mic, Square } from 'react-feather';
import { useVideoCall } from '../../helpers/context/videoCallContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallAudioInputs = () => {
   const [inputs, setInputs] = useState(false);
   const { audioInput, audioInputs, changeAudioInput } = useVideoCall();

   const handleSelectAudioInput = (deviceId) => () => {
      changeAudioInput(deviceId);
   }

   if (!audioInputs?.length) {
      return ''
   }

   return (
      <div className={`${styles.zoomcontent} me-2`}>
         <div>
            <Dropdown
               show={inputs}
               className={styles.dropdown}
               onToggle={() => setInputs(!inputs)}
            >

               <Dropdown.Toggle
                  as='a'
                  bsPrefix='text-dark'
               >
                  <Mic />
               </Dropdown.Toggle>

               <Dropdown.Menu
                  align="end"
                  className={styles.dropdownMenu}
               >
                  {audioInputs.map((item) => {
                     return (
                        <Dropdown.Item
                           as='button'
                           key={item.deviceId}
                           className={styles.dropdownItem}
                           onClick={handleSelectAudioInput(item.deviceId)}
                        >
                           {(item.deviceId === audioInput?.deviceId) ? (
                              <CheckSquare />
                           ) : (
                              <Square />
                           )}
                           {item.label}
                        </Dropdown.Item>
                     );
                  })}
               </Dropdown.Menu>
            </Dropdown>
         </div>
      </div>
   );
};

export default VideoCallAudioInputs;
