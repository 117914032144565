import styles from '../../assets/css/progress/styles.module.css'

const Progress = ({ active, title = 'Processing...' }) => {
   if (!active) {
      return ''
   }

   return (
      <div className={styles["block-ui"]}>
         <div className={styles["block-ui-overlay"]} />
         <div className={styles["block-ui-message-container"]}>
            <div className={styles["block-ui-message"]}>
               <div className={styles["block-ui-loader"]}>
                  <span>
                     <i className="fa fa-circle-notch fa-spin me-2"></i>
                     {title}
                  </span>
               </div>
            </div>
         </div>
      </div>
   )
}

export { Progress };