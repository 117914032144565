import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { GetInclusionsByServceIdAndStp } from "../../services/flu";
import getOptDashboard3 from "./options/dashboard3";

const Dashboard3 = () => {
   const [stp, setStp] = useState("0");
   const [serviceId, setServiceId] = useState(1652);
   const [result, setResult] = useState({ Title: 'Risk groups', Data: [] });

   const fetchData = async (serviceId, stp) => {
      const result = await GetInclusionsByServceIdAndStp(serviceId, stp);
      if(result)setResult(result.d)
   };

   useEffect(() => {
      fetchData(serviceId, stp);
   }, [serviceId, stp]);

   return (
      <div className="border">
         <div className="p-2">
            <div className="row justify-content-between align-items-center">
               <div className="col-auto">
                  <div className="row">
                     <div className="col-auto">
                        <select
                           id="ddlService3"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setServiceId(Number(e.target.value))}
                           value={serviceId}
                        >
                           <option value="1652">London Flu</option>
                           <option value="1653">London PPV</option>
                           <option value="1651">National Flu</option>
                        </select>
                     </div>
                     <div className="col-auto">
                        <select
                           id="ddlArea3"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setStp(e.target.value)}
                           value={stp}
                        >
                           <option value="0">All STPs</option>
                           <option value="North Central London">NCL</option>
                           <option value="North East London">NEL</option>
                           <option value="North West London">NWL</option>
                           <option value="South East London">SEL</option>
                           <option value="South West London">SWL</option>
                        </select>
                     </div>
                  </div>
               </div>
               <div className="col-auto">
                  <h5 className="flu-title text-right mb-0">
                     {result.Title}
                  </h5>
               </div>
            </div>
         </div>

         <HighchartsReact
            options={getOptDashboard3(result.Data)}
            highcharts={Highcharts}
         />
      </div>
   );
};

export default Dashboard3;
