import { Link, NavLink } from "react-router-dom";

const Header = () => {
   return (
      <header className="wrapper bg-soft-primary">
         <nav className="navbar navbar-expand-lg center-nav transparent navbar-light">
            <div className="container flex-lg-row flex-nowrap align-items-center">
               <div className="navbar-brand w-100">
                  <Link to="/">
                     <span style={ { padding: "5px 8px 13px 5px", background: "#000" } }>
                        <img
                           src={ `${process.env.PUBLIC_URL}/assets/img/sonarhealth-logo.png` }
                           /*srcSet={`${process.env.REACT_APP_ROUTE_BASE_URL}/assets/img/sonarhealth-logo.png 2x`}*/
                           alt=""
                        />
                     </span>
                  </Link>
               </div>
               <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                  <div className="offcanvas-header d-lg-none">
                     <h3 className="text-white fs-30 mb-0">SonarHealth</h3>
                     <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                     <ul className="navbar-nav">
                        <li className="nav-item">
                           <NavLink className="nav-link" to="/poc-covid19">PoC Covid-19 Signup</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink className="nav-link" to="/flu-service">London Flu Service</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink className="nav-link" to="/cpcs-service">CPCS Service</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink className="nav-link" to="/video-Consultation">Video Call</NavLink>
                        </li>
                        <li className="nav-item">
                           <NavLink className="nav-link" to="/contact-us">Contact us</NavLink>
                        </li>
                     </ul>
                  </div>
               </div>

               <div className="navbar-other w-100 d-flex ms-auto">
                  <ul className="navbar-nav flex-row align-items-center ms-auto">
                  <li className="nav-item"><a className="nav-link" href="/contractors"><i className="uil uil-signin d-none"></i> Login</a></li>
                     <li className="nav-item"><NavLink className="nav-link" to="/signup"><i className="uil uil-user-plus d-none"></i> New user?</NavLink></li>
                     <li className="nav-item d-lg-none">
                        <button className="hamburger offcanvas-nav-btn"><span></span></button>
                     </li>
                  </ul>

               </div>

            </div>

         </nav>

         <div className="offcanvas offcanvas-end text-inverse" id="offcanvas-info" data-bs-scroll="true">
            <div className="offcanvas-header">
               <h3 className="text-white fs-30 mb-0">SonarHealth</h3>
               <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body pb-6">
               <div className="widget mb-8">
                  <p>SonarHealth is a multipurpose HTML5 template with various layouts which will be a great solution for your business.</p>
               </div>

               <div className="widget mb-8">
                  <h4 className="widget-title text-white mb-3">Contact Info</h4>
                  <address> Moonshine St. 14/05 <br /> Light City, London </address>
                  <a href="mailto:first.last@email.com">info@email.com</a><br /> 00 (123) 456 78 90
               </div>

               <div className="widget mb-8">
                  <h4 className="widget-title text-white mb-3">Learn More</h4>
                  <ul className="list-unstyled">
                     <li><a href="!#">Our Story</a></li>
                     <li><a href="!#">Terms of Use</a></li>
                     <li><a href="!#">Privacy Policy</a></li>
                     <li><a href="!#">Contact Us</a></li>
                  </ul>
               </div>

               <div className="widget">
                  <h4 className="widget-title text-white mb-3">Follow Us</h4>
                  <nav className="nav social social-white">
                     <a href="!#"><i className="uil uil-twitter"></i></a>
                     <a href="!#"><i className="uil uil-facebook-f"></i></a>
                     <a href="!#"><i className="uil uil-dribbble"></i></a>
                     <a href="!#"><i className="uil uil-instagram"></i></a>
                     <a href="!#"><i className="uil uil-youtube"></i></a>
                  </nav>

               </div>

            </div>

         </div>
      </header>
   );
}

export default Header;
