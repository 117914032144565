import classNames from "classnames";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../helpers/context/authContext";
import { getChannelByShortenCode } from "../../services/videoChat";
import { Progress } from "../../components/progress";
import useEffectOnce from "../../helpers/hooks/useEffectOnce";
import VideoCallFailed from "./VideoCallFailed";
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallShortenCode = () => {
   const { auth } = useAuth()
   const navigate = useNavigate()
   const { shortenCode } = useParams()
   const [failed, setFailed] = useState(false)
   const [progress, setProgress] = useState(true)

   const redirectToVideoCall = async () => {
      try {
         if (!shortenCode?.trim()) {
            setProgress(false)
            setFailed(true)
            return
         }

         const channel = await getChannelByShortenCode(shortenCode.trim(), auth.Token);

         if (!channel) {
            setProgress(false)
            setFailed(true)
            return
         }

         navigate(
            `/video-consultation?locationId=${channel.LocationId}&userId=${channel.UserId}&patientId=${channel.PatientId}&meetingId=${channel.MeetingId}`,
            { replace: true }
         )
      }
      catch {
         setProgress(false)
         setFailed(true)
      }
   }

   useEffectOnce(() => {
      redirectToVideoCall()
   }, [])

   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container p-4 text-center">
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                     <h1 className="display-1 mb-3">
                        Video Consultation
                     </h1>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container p-4">
               <section className="wrapper bg-light">
                  <div
                     id="videoCallWrapper"
                     className="container p-0 bg-white position-relative"
                  >
                     <div className={classNames(styles.videocall)}>
                        {failed && (
                           <VideoCallFailed
                              message='Failed to fetch user or patient.'
                           />
                        )}
                        <Progress active={progress} />
                     </div>
                  </div>
               </section>
            </div>
         </section>
      </>
   )
};

export default VideoCallShortenCode;
