import { Maximize, Minimize } from 'react-feather';
import { toggleFullScreen } from '../../helpers/utils';
import { useVideoCall } from '../../helpers/context/videoCallContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallFullScreen = () => {
  const { videoFullScreen } = useVideoCall();

  const handleToggleVideoScreen = (e) => {
    e.preventDefault();
    toggleFullScreen('#videoCallWrapper');
  }

   return (
      <div className={styles.zoomcontent}>
      <a
        href='#'
        className='text-dark'
        onClick={handleToggleVideoScreen}
      >
        {videoFullScreen ? <Minimize /> : <Maximize />}
      </a>
    </div>
  );
};

export default VideoCallFullScreen;
