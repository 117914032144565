import classNames from "classnames";
import VideoCallContent from "./videoCallContent";
import { Progress } from "../../components/progress";
import { useVideoCall } from "../../helpers/context/videoCallContext";
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallWrapper = () => {
   const { progress, videoMaximize, videoFullScreen } = useVideoCall()

   return (
      <section className="wrapper bg-light">
         <div
            id="videoCallWrapper"
            className="container p-0 bg-white position-relative"
         >
            <div className={classNames(
               styles.videocall,
               { [styles.active]: (videoMaximize || videoFullScreen) }
            )}>
               <VideoCallContent />
            </div>

            <Progress active={progress} />
         </div>
      </section>
   );
};

export default VideoCallWrapper;
