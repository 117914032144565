import { v4 as uuid } from 'uuid'
import { getRoles, getToken } from "../jwt";
import { SONAR_AUTH0_URL, SONAR_GATEWAY_API_URL } from '../env';

export const getJWTCookie = async () => {
   try {
      const jwt = await getToken();
      const roles = await getRoles();

      return {
         locationId: roles.LocationId,
         userId: roles.UserId,
         token: jwt.Token.token
      }
   } catch {
      return {};
   }
}

export const getJWTToken = async () => {
   const { token } = await getJWTCookie();
   return token;
}

export const getURLParams = () => {
   const params = new URLSearchParams(window.location.search)
   return {
      mode: params.get('mode'),
      meetingId: params.get('meetingId'),
      patientId: params.get('patientId')
   };
}

export const openFullScreen = selector => {
   const element = document.querySelector(selector);

   if (element?.requestFullscreen) {
      element.requestFullscreen();
   } else if (element?.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
   } else if (element?.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
   }
}

export const closeFullScreen = () => {
   if (document?.exitFullscreen) {
      document.exitFullscreen();
   } else if (document?.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
   } else if (document?.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
   }
}

export const capitalize = (word) => {
   if (!word || !word.trim()) return word;
   if (word.length === 1) return word.charAt(0).toUpperCase();
   return (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
}

export const scrollDownByRef = (ref) => {
   if (!ref.current) return;

   window.scrollTo({
      top: ref.current?.offsetTop,
      behavior: 'smooth',
   });
};

export const toggleFullScreen = (selector) => {
   if (document?.fullscreenElement) {
      closeFullScreen();
   } else {
      openFullScreen(selector);
   }
}

export const getUid = (withSeparator = false) => {
   if (withSeparator) {
      return uuid().toLowerCase();
   }

   return uuid().replace(/-/g, '').toLowerCase();
}

export const getQueryParamsString = params => {
   const entries = Object.entries(params);
   const queryParams = entries.map(([key, value]) => `${key.charAt(0).toLowerCase()}${key.slice(1)}=${value.toString()}`);
   return queryParams.join("&");
}

export const getUrlSearchParams = () => {
   const searchParams = new URLSearchParams(window.location.search)

   const entries = []
   for (const [key, value] of searchParams.entries()) {
      entries.push([key.toLowerCase(), value]);
   }

   const query = Object.fromEntries(entries)
   return query
}

export const isSonarUrl = (url) => {
   const isAuth0 = url.toLowerCase().includes(SONAR_AUTH0_URL.toLowerCase())
   const isGateway = url.toLowerCase().includes(SONAR_GATEWAY_API_URL.toLowerCase())
   return (isAuth0 || isGateway)
}