import AgoraRTC from 'agora-rtc-sdk-ng';
import { AGORA_RTC_APP_ID } from '../env';

const getDevices = async () => {
   const devices = await AgoraRTC.getDevices();
   const audioInputs = devices.filter((item) => item.kind === 'audioinput');
   const videoInputs = devices.filter((item) => item.kind === 'videoinput');

   return {
      audioInputs,
      videoInputs
   }
}

const getClient = () => {
   const client = AgoraRTC.createClient({
      mode: "rtc",
      codec: "vp8"
   });

   return client;
}

const join = async (client, { channelName, token, videoId, audioId, onUserPublished, onUserUnpublished }) => {
   try {
      if (!client || client.connectionState === 'CONNECTED') {
         return;
      }

      client.on("user-published", async (user, mediaType) => {
         await client.subscribe(user, mediaType);
         console.log("subscribe success");

         if (mediaType === "video") {
            const remotePlayer = document.querySelector('#remotePlayer');
            const remoteVideoTrack = user.videoTrack;

            if (remotePlayer) {
               remoteVideoTrack?.play(remotePlayer);
            }

            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack?.play();

            onUserPublished({
               remoteVideoTrack,
               remoteAudioTrack
            });
         }

         if (mediaType === "audio") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack?.play();

            onUserPublished({
               remoteVideoTrack: null,
               remoteAudioTrack
            });
         }

         client.on("user-unpublished", user => {
            console.log(user.uid + "has left the channel");
            onUserUnpublished();
         });
      });

      const userUid = await client.join(AGORA_RTC_APP_ID, channelName, token);

      const localPlayer = document.querySelector('#localPlayer');
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await localVideoTrack.setDevice(videoId);

      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await localAudioTrack.setDevice(audioId);

      await client.publish([localAudioTrack, localVideoTrack]);
      console.log("publish success!");


      if (localPlayer) {
         localVideoTrack?.play(localPlayer);
      }

      localAudioTrack.play();

      return {
         userUid,
         localVideoTrack,
         localAudioTrack
      }
   }
   catch {
      return {
         localVideoTrack: null,
         localAudioTrack: null
      }
   }
}

const leave = async (client, { localVideoTrack, localAudioTrack, remoteVideoTrack, remoteAudioTrack }) => {
   try {
      closeTrack(localVideoTrack);
      closeTrack(localAudioTrack);

      closeTrack(remoteVideoTrack);
      closeTrack(remoteAudioTrack);

      if (client && client.connectionState === 'CONNECTED') {
         await client.leave();
         console.log("You left the channel");
      }
   }
   catch { }
}

const closeTrack = (track) => {
   try {
      if (!track) {
         return;
      }

      if (track.isPlaying) {
         track.stop();
      }

      track.close();
   }
   catch { }
}

export const agoraVideoCall = {
   join,
   leave,
   getClient,
   getDevices,
}