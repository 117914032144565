import { saveContact } from '../../services/contact';

const ContactForm = () => {
   const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);
      data.agree = document.getElementById("agree").checked;

      const result = await saveContact(data);
      if (result) e.target.reset();
   }

   return (
      <form className="contact-form needs-validation" onSubmit={ handleSubmit }>
         <div className="messages"></div>
         <div className="row gx-4">
            <div className="col-md-12">
               <div className="form-floating mb-4">
                  <input type="text" id="name" name="name" className="form-control form-control-sm" placeholder="Name" />
                  <label htmlFor="name">Name *</label>
               </div>
            </div>
            <div className="col-md-12">
               <div className="form-floating mb-4">
                  <input type="text" id="phone" name="phone" className="form-control form-control-sm" placeholder="Phone" />
                  <label htmlFor="phone">Phone</label>
               </div>
            </div>
            <div className="col-md-12">
               <div className="form-floating mb-4">
                  <input type="email" id="email" name="email" className="form-control form-control-sm" placeholder="jane.doe@example.com" />
                  <label htmlFor="email">Email *</label>
               </div>
            </div>
            <div className="col-md-12">
               <div className="form-select-wrapper mb-4">
                  <select id="department" name="department" className="form-select form-control-sm" defaultValue={ "" } >
                     <option disabled value="">Select a department</option>
                     <option value="Sales">Sales</option>
                     <option value="Marketing">Marketing</option>
                     <option value="CustomerSupport">Customer Support</option>
                  </select>
               </div>
            </div>
            <div className="col-12">
               <div className="form-floating mb-4">
                  <textarea id="message" name="message" className="form-control form-control-sm" placeholder="Your message" style={ { height: "150px", resize: "none" } }></textarea>
                  <label htmlFor="message">Message *</label>
               </div>
            </div>
            <div className="col-12">
               <div className="form-check mb-4">
                  <input type="checkbox" id="agree" name="agree" className="form-check-input" />
                  <label htmlFor="agree" className="form-check-label" style={ { fontSize: "0.875rem" } }>
                     I agree to <a href="!#" className="hover" onClick={ e => e.preventDefault() }>terms and policy</a>.
                  </label>
               </div>
            </div>
            <div className="col-12">
               <input type="submit" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message" />
               <p className="text-muted" style={ { fontSize: "0.875rem" } }><strong>*</strong> These fields are required.</p>
            </div>
         </div>
      </form>
   )
}

export default ContactForm;
