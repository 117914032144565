const AUTH_LOCAL_STORAGE_KEY = 'sonar-auth-pgds'

const getAuthStorage = () => {
  try {
    if (!localStorage) {
      return undefined
    }

    const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

    if (!lsValue) {
      return undefined
    }

    const auth = JSON.parse(lsValue)
    return auth ?? undefined
  }
  catch (error) {
    return undefined
  }
}

const setAuthStorage = (auth) => {
  try {
    if (!localStorage) {
      return
    }

    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  }
  catch { }
}

const removeAuthStorage = () => {
  try {
    if (!localStorage) {
      return
    }

    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  }
  catch { }
}

export {
  getAuthStorage,
  setAuthStorage,
  removeAuthStorage,
  AUTH_LOCAL_STORAGE_KEY,
}