import { useVideoCall } from '../../helpers/context/videoCallContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallTimeValues = () => {
  const { timeValues } = useVideoCall();

  return (
    <div
      id={styles.basicUsage}
      className='text-dark me-2'
    >
      {timeValues}
    </div>
  );
};

export default VideoCallTimeValues;
