import { Maximize2, Minimize2 } from 'react-feather';
import { useVideoCall } from '../../helpers/context/videoCallContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallMaximize = () => {
   const { videoFullScreen, videoMaximize, toggleVideoMaximize } = useVideoCall();

   const handleToggleVideoScreen = (e) => {
      e.preventDefault();
      toggleVideoMaximize();
   }

   if (videoFullScreen) {
      return '';
   }

   return (
      <div className={`${styles.zoomcontent} me-2`}>
         <a
            href='#'
            className='text-dark'
            onClick={handleToggleVideoScreen}
         >
            {videoMaximize ? <Minimize2 /> : <Maximize2 />}
         </a>
      </div>
   );
};

export default VideoCallMaximize;
