import { Mic, MicOff, Phone, PhoneOff, Video, VideoOff } from 'react-feather';
import { useVideoCall } from '../../helpers/context/videoCallContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallActions = () => {
   const { videoCall, muteVideo, muteAudio, toggleVideoCall, toggleMuteVideo, toggleMuteAudio } = useVideoCall();

   return (
      <div className={`${styles.centerCon} text-center`}>
         <ul>
            <li>
               <button
                  type='button'
                  data-tippy-content='Hold'
                  className={`${styles.iconBtn} ${styles.buttonEffect} ${styles.pause} btn-light`}
                  onClick={toggleMuteVideo}
               >
                  {muteVideo ? <VideoOff /> : <Video />}
               </button>
            </li>
            <li>
               <button
                  type='button'
                  className={`${styles.iconBtn} ${styles.buttonEffect} ${styles.phone} btn-danger`}
                  onClick={toggleVideoCall}
               >
                  {videoCall ? <Phone /> : <PhoneOff />}
               </button>
            </li>
            <li>
               <button
                  type='button'
                  data-tippy-content='Mute'
                  className={`${styles.iconBtn} ${styles.buttonEffect} ${styles.mic} btn-light`}
                  onClick={toggleMuteAudio}
               >
                  {muteAudio ? <MicOff /> : <Mic />}
               </button>
            </li>
         </ul>
      </div>
   );
};

export default VideoCallActions;
