
export const SONAR_AUTH0_URL = process.env.REACT_APP_SONAR_AUTH0_URL ?? ''
export const SONAR_AUTH0_USERNAME = process.env.REACT_APP_SONAR_AUTH0_USERNAME ?? ''
export const SONAR_AUTH0_PASSWORD = process.env.REACT_APP_SONAR_AUTH0_PASSWORD ?? ''
export const SONAR_AUTH0_PIN = process.env.REACT_APP_SONAR_AUTH0_PIN ?? ''

export const SONAR_API_KEY = process.env.REACT_APP_SONAR_API_KEY ?? ''
export const SONAR_GATEWAY_API_URL = process.env.REACT_APP_SONAR_GATEWAY_API_URL ?? ''

export const VIDEOCHAT_API_URL = process.env.REACT_APP_VIDEOCHAT_API_URL ?? ''
export const PATIENTS_API_URL = process.env.REACT_APP_PATIENTS_API_URL ?? ''

export const AGORA_RTC_APP_ID = process.env.REACT_APP_AGORA_RTC_APP_ID ?? ''