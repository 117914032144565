import { useState } from 'react';
import { removeAuthStorage, setAuthStorage } from '../../auth';
import { getJWTCookie } from '../../auth/cookie';
import { getUserById } from '../../../services/auth0';
import { getPatientById } from '../../../services/patient';
import { getChannelByMeetingId } from '../../../services/videoChat';
import { getUrlSearchParams } from '../../utils';
import useEffectOnce from '../../hooks/useEffectOnce';
import AuthContext from './index';

const AuthProvider = ({ children }) => {
   const [auth, setAuth] = useState(null);
   const [user, setUser] = useState(null);
   const [patient, setPatient] = useState(null);
   const [channel, setChannel] = useState(null);
   const [failed, setFailed] = useState(false);

   const saveAuth = auth => {
      setAuth(auth)

      if (auth) {
         setAuthStorage(auth)
      }
      else {
         removeAuthStorage()
      }
   }

   const logout = () => {
      saveAuth(null);
      setUser(null);
      setPatient(null);
      setChannel(null);
   }

   const fetchData = async () => {
      try {
         const query = getUrlSearchParams();

         if (!query.userid || !query.locationid || !query.patientid || !query.meetingid) {
            throw new Error('Parameters required');
         }

         const auth = await getJWTCookie();

         if (!auth) {
            throw new Error('Not authorized');
         }

         auth.UserId = query.userid;
         auth.LocationId = query.locationid;
         saveAuth(auth);

         const user = await getUserById(query.userid, auth.Token);

         if (!user) {
            throw new Error('User not found');
         }

         setUser(user);

         const dataGetPatient = {
            LocationId: auth.LocationId,
            UserId: auth.UserId,
            Id: query.patientid
         }
         const patient = await getPatientById(dataGetPatient, auth.Token);

         if (!patient) {
            throw new Error('Patient not found');
         }

         setPatient(patient);

         const channel = await getChannelByMeetingId(query.meetingid, auth.Token);

         if (!channel) {
            throw new Error('Channel not found');
         }

         setChannel(channel);
         setFailed(false);
      }
      catch (error) {
         console.log({ error });
         setFailed(true);
      }
   }

   useEffectOnce(() => {
      fetchData()
      return () => logout()
   }, [])

   return (
      <AuthContext.Provider
         value={{
            auth,
            user,
            patient,
            channel,
            failed,
         }}
      >
         {children}
      </AuthContext.Provider>
   )
}

export default AuthProvider;