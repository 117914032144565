import { Modal } from "react-bootstrap";
import { useVideoCall } from "../../helpers/context/videoCallContext";

const VideoCallTerms = () => {
   const { showTerms, setShowTerms, setAcceptTerms } = useVideoCall()

   const handleClose = () => {
      setShowTerms(false)
      setAcceptTerms(false)
   }

   const handleAccept = () => {
      setShowTerms(false)
      setAcceptTerms(true)
   }

   return (
      <Modal
         backdrop="static"
         centered={true}
         keyboard={false}
         scrollable={true}
         show={showTerms}
         onHide={handleClose}
         contentClassName="border-0"
      >
         <Modal.Header
            className="px-3 py-2"
            style={{ backgroundColor: "#009ef7" }}
         >
            <Modal.Title
               className="text-white fs-6"
            >
               Start Video Consultation
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <p className="fw-bold mb-1">
               Terms & Conditions
            </p>
            <div style={{ fontSize: '0.8rem' }}>
               <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ultricies pharetra dolor, at iaculis erat posuere nec. Nunc et est vitae sapien pretium varius in non nulla. Suspendisse blandit, ex et blandit vestibulum, nisi tellus vehicula enim, ut tristique sem lectus a arcu. Vestibulum eu orci at mauris vehicula porta. Mauris vitae tempor massa. Aenean tristique orci felis, eget pharetra ex pulvinar in. Phasellus eu ex risus. Suspendisse sit amet euismod dui. Donec et lacinia nisl. Nulla nec efficitur magna. Praesent suscipit ornare arcu at cursus. Sed euismod justo sit amet nulla molestie, ac mattis justo vehicula. Morbi neque nibh, placerat ut congue vitae, sagittis nec nibh. Nam aliquet metus auctor, blandit lorem nec, mollis sapien.</p>
               <p className="mb-3">Morbi vel cursus turpis, pretium ullamcorper neque. Suspendisse aliquet sodales dui, vitae sodales velit blandit non. Donec a ullamcorper nulla. Maecenas rutrum eleifend vehicula. Aliquam erat volutpat. Ut molestie elementum ex. Phasellus sodales feugiat sem, a imperdiet ante aliquam sed. Aliquam elementum sed ipsum id faucibus. Sed rhoncus laoreet ligula, in consectetur augue dictum et. Maecenas velit leo, auctor non aliquam at, auctor elementum augue.</p>
               <p className="mb-0">Sed vitae velit orci. Quisque a aliquet eros. Vestibulum congue vehicula nulla, sed porttitor mi ultricies a. Phasellus eleifend mattis nulla vel maximus. Aliquam commodo rutrum lectus, a consectetur risus vulputate ut. Sed condimentum libero sed urna egestas, at tristique metus tempus. Nam dolor mauris, imperdiet quis hendrerit et, mollis quis dolor. Donec tempor orci et aliquet molestie. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque feugiat lacinia gravida. Phasellus ultrices lacus vel leo condimentum tempus. Sed quis neque eros. Sed a tincidunt massa. Suspendisse sed semper nunc, ut porta lorem. Sed scelerisque condimentum tempus.</p>
            </div>
         </Modal.Body>
         <Modal.Footer
            className="justify-content-center px-3 py-1"
         >
            <button
               type="button"
               className="btn btn-success btn-sm"
               onClick={handleAccept}
            >
               Accept
            </button>
            <button
               type="button"
               className="btn btn-secondary btn-sm"
               onClick={handleClose}
            >
               Cancel
            </button>
         </Modal.Footer>
      </Modal>
   );
};

export default VideoCallTerms;
