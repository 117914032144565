import useTimer from 'easytimer-react-hook';
import { useState, useEffect } from 'react';
import { agoraVideoCall } from "../../agora/videoCall2";
import { updatePatientJoin } from '../../../services/videoChat';
import { useAuth } from "../authContext";
import VideoCallContext from ".";

const VideoCallProvider = (props) => {
   const { auth, user, patient, channel } = useAuth();

   const [timer] = useTimer();
   const [timeValues, setTimeValues] = useState('00:00:00');
   const [progress, setProgress] = useState(false);
   const [showTerms, setShowTerms] = useState(false);
   const [acceptTerms, setAcceptTerms] = useState(false);
   const [videoCall, setVideoCall] = useState(false);
   const [videoMaximize, setVideoMaximize] = useState(false);
   const [videoFullScreen, setVideoFullScreen] = useState(false);
   const [userUid, setUserUid] = useState('');
   const [client, setClient] = useState(null);
   const [muteVideo, setMuteVideo] = useState(true);
   const [muteAudio, setMuteAudio] = useState(true);
   const [videoInputs, setVideoInputs] = useState([]);
   const [audioInputs, setAudioInputs] = useState([]);
   const [videoInput, setVideoInput] = useState(null);
   const [audioInput, setAudioInput] = useState(null);
   const [localVideoTrack, setLocalVideoTrack] = useState(null);
   const [localAudioTrack, setLocalAudioTrack] = useState(null);
   const [remoteVideoTrack, setRemoteVideoTrack] = useState(null);
   const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);

   const tickTimer = () => {
      const timeValues = timer.getTimeValues().toString();
      setTimeValues(timeValues);
   }

   const startTimer = () => {
      setTimeValues('00:00:00');

      timer.start();
      timer.addEventListener("secondsUpdated", tickTimer);
   }

   const stopTimer = () => {
      timer.stop();
      timer.removeEventListener("secondsUpdated", tickTimer);
   }

   const toggleVideoCall = async () => {
      if (videoCall) {
         await leaveVideoCall();
      } else {
         await joinVideoCall();
      }

      setVideoCall(!videoCall);
   }

   const getDevices = async () => {
      const { videoInputs, audioInputs } = await agoraVideoCall.getDevices();

      setVideoInputs(videoInputs);
      setAudioInputs(audioInputs);

      setVideoInput(videoInputs[0]);
      setAudioInput(audioInputs[0]);

      return { videoInputs, audioInputs }
   }

   const joinVideoCall = async () => {
      if (!user || !patient || !channel || !acceptTerms) {
         return;
      }

      setProgress(true);

      if (auth?.UserId) {
         const dataUpdate = {
            MeetingId: channel.MeetingId,
            UserId: auth.UserId,
         }
         updatePatientJoin(dataUpdate, auth.Token)
      }

      const { videoInputs, audioInputs } = await getDevices()

      const rtcClient = agoraVideoCall.getClient()
      setClient(rtcClient)

      const { userUid, localVideoTrack, localAudioTrack } = await agoraVideoCall.join(rtcClient, {
         channelName: channel.MeetingId,
         token: channel.MeetingToken,
         videoId: videoInputs[0].deviceId,
         audioId: audioInputs[0].deviceId,
         onUserPublished: ({ remoteVideoTrack, remoteAudioTrack }) => {
            setRemoteVideoTrack(remoteVideoTrack);
            setRemoteAudioTrack(remoteAudioTrack);
         },
         onUserUnpublished: () => {
            setRemoteVideoTrack(null);
            setRemoteAudioTrack(null);
         }
      });

      startTimer();
      setUserUid(userUid);
      setProgress(false);
      setVideoCall(true);
      setMuteVideo(false);
      setMuteAudio(false);
      setLocalVideoTrack(localVideoTrack);
      setLocalAudioTrack(localAudioTrack);
   }

   const leaveVideoCall = async () => {
      setProgress(true);

      agoraVideoCall.leave(client, {
         localVideoTrack,
         localAudioTrack,
         remoteVideoTrack,
         remoteAudioTrack
      });

      stopTimer();
      setUserUid('');
      setClient(null);
      setProgress(false);
      setVideoCall(false);
      setMuteVideo(true);
      setMuteAudio(true);
      setLocalVideoTrack(null);
      setLocalAudioTrack(null);
      setRemoteVideoTrack(null);
      setRemoteAudioTrack(null);
   }

   const changeVideoInput = async (deviceId) => {
      try {
         const videoInput = videoInputs.find(item => item.deviceId === deviceId);

         if (videoInput) {
            await localVideoTrack.setDevice(videoInput.deviceId);
            setVideoInput(videoInput);
         }
      }
      catch { }
   }

   const changeAudioInput = async (deviceId) => {
      try {
         const audioInput = audioInputs.find(item => item.deviceId === deviceId);

         if (audioInput) {
            await localAudioTrack.setDevice(audioInput.deviceId);
            setAudioInput(audioInput);
         }
      }
      catch { }
   }

   const toggleMuteVideo = async () => {
      await localVideoTrack.setEnabled(muteVideo);
      setMuteVideo(!muteVideo);
   }

   const toggleMuteAudio = async () => {
      await localAudioTrack.setEnabled(muteAudio);
      setMuteAudio(!muteAudio);
   }

   const toggleVideoMaximize = () => {
      setVideoMaximize(!videoMaximize);
   }

   const fullScreenChange = () => {
      setVideoFullScreen(document.fullscreenElement ? true : false);
   }

   useEffect(() => {
      document.addEventListener("fullscreenchange", fullScreenChange);
      return () => document.removeEventListener("fullscreenchange", fullScreenChange);
   }, [])

   useEffect(() => {
      if (user && patient && channel) {
         setShowTerms(true);
      }
   }, [
      user,
      patient,
      channel
   ])

   useEffect(() => {
      joinVideoCall()
   }, [
      user,
      patient,
      channel,
      acceptTerms
   ])

   return (
      <VideoCallContext.Provider
         value={{
            timeValues,
            progress,
            showTerms,
            acceptTerms,
            videoCall,
            videoMaximize,
            videoFullScreen,
            userUid,
            videoInputs,
            audioInputs,
            videoInput,
            audioInput,
            muteVideo,
            muteAudio,
            setShowTerms,
            setAcceptTerms,
            startTimer,
            stopTimer,
            toggleVideoCall,
            joinVideoCall,
            leaveVideoCall,
            changeVideoInput,
            changeAudioInput,
            toggleMuteVideo,
            toggleMuteAudio,
            toggleVideoMaximize
         }}
      >
         {props.children}
      </VideoCallContext.Provider>
   );
};

export default VideoCallProvider;
