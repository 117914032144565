import { Routes, Route } from "react-router-dom";
import Home from '../pages/home';
import Covid19 from '../pages/poc-covid19/poc-covid19';
import Contact from '../pages/contact/contact';
import UserGuides from '../pages/user-guides';
// import VideoChat from '../pages/video-chat/video-chat';
import NotFound from '../pages/_404';
import CPCS from "../pages/cpcs/cpcs";
import SignUp from "../pages/signup/signup";
import FluService from "../pages/flu-service/flu-service";
import VideoCall from "../pages/video-call/videoCall";
import VideoCallShortenCode from "../pages/video-call/videoCallShortenCode";
//const LazyHome = React.lazy(() => import("../pages/home"));

const DefaultRoutes = () => {
   /*
   let routes = useRoutes([
     { path: "/", element: <React.Suspense fallback="Loading..."><LazyHome /></React.Suspense> },
     { path: "/mybooks", element: <MyBooks /> },
     { path: "/favorites", element: <Favorites /> },
     { path: "/public/contact-us", element: <Contact /> },
     { path: "/*", element: <NotFound /> }
   ]);
   return routes;
   */

   return (
      <Routes>
         <Route exact path="/" element={<Home />} /*element={<React.Suspense fallback="Loading..."><LazyHome /></React.Suspense>}*/ />
         <Route path="/poc-covid19" element={<Covid19 />} />
         <Route path="/user-guides/:section" element={<UserGuides />} />
         <Route path="/cpcs-service" element={<CPCS />} />
         {/* <Route path="/video-consultation" element={<VideoChat />} /> */}
         <Route path="/video-consultation" element={<VideoCall />} />
         <Route path="/sh/:shortenCode" element={<VideoCallShortenCode />} />
         <Route path="/contact-us" element={<Contact />} />
         <Route path="/signup" element={<SignUp />} />
         <Route path="/flu-service" element={<FluService />} />
         <Route path="/*" element={<NotFound />} />
         {/*<Route exact path="/quotes/:quoteID/*" element={<QuoteDetail/> } />*/}
      </Routes>
   );

};

export default DefaultRoutes;
