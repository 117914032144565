const VideoCallFailed = ({ message }) => {
   return (
      <div className="d-flex align-items-center justify-content-center h-100">
         <p className="mb-0 text-center py-4">
            {message}
         </p>
      </div>
   );
};

export default VideoCallFailed;
