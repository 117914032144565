import { getToken as getTokenApi, refreshToken as refreshTokenApi } from "../../services/auth0";

const createCookie = (name, value, days) => {
   try {
      const options = {
         path: '/',
         samesite: 'strict'
      };

      if (days) {
         const date = new Date();
         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
         options.expires = date.toUTCString();
      }

      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
         updatedCookie += "; " + optionKey;
         let optionValue = options[optionKey];
         if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
         }
      }

      document.cookie = updatedCookie;
   }
   catch (error) {
      throw error
   }
};

const readCookie = name => {
   try {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');

      for (let i = 0; i < ca.length; i++) {
         let c = ca[i];

         while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
         }

         if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
         }
      }

      return null;
   }
   catch (error) {
      throw error
   }
};

const refreshToken = async jwt => {
   try {
      const tokenParts = jwt.Token.token.split(".");
      const payload = JSON.parse(atob(tokenParts[1]));
      const date = new Date(1000 * payload.exp);
      const today = new Date();

      if (date >= today) {
         return jwt;
      }

      const token = await refreshTokenApi(jwt.Token.refreshToken)

      if (!token) {
         return null;
      }

      jwt.Token = token
      createCookie("jwttoken", JSON.stringify(jwt));

      return jwt;
   }
   catch (error) {
      throw error
   }
};

const getToken = async () => {
   try {
      const cookie = readCookie("jwttoken");

      if (!cookie) {
         const token = await getTokenApi()

         const jwt = { Token: token }
         createCookie("jwttoken", JSON.stringify(jwt));

         return jwt
      }

      const jwt = JSON.parse(decodeURIComponent(cookie));
      const returnedJWT = await refreshToken(jwt);
      return returnedJWT;
   }
   catch (error) {
      throw error
   }
};

export const getJWTCookie = async () => {
   try {
      const jwt = await getToken();

      if (!jwt) {
         return null;
      }

      const auth = {
         Token: jwt.Token.token,
      }

      return auth;
   }
   catch (error) {
      throw error
   }
}