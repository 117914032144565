const Home = () => {
   return (
      <>
         <section className="wrapper bg-gradient-primary">
            <div className="container pt-10 pt-md-14 pb-8 text-center">
               <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                  <div className="col-lg-7">
                     <figure>
                     <img className="w-auto" src={`${process.env.PUBLIC_URL}/assets/img/illustrations/i2.png`} alt="" />
                     </figure>
                  </div>
                  <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                     <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">Welcome to the Sonar Healthcare Network.</h1>
                     <p className="lead fs-lg mb-7">We help to improve patient outcomes by having clinical information available at any time, and to make more fluid the communication between healthcare organisations.</p>
                     <span><a href='!#' className="btn btn-primary rounded-pill me-2">Find out more</a></span>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container pt-14 pt-md-16">
               <div className="row text-center">
                  <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                     <h2 className="fs-16 text-uppercase text-muted mb-3">What We Do?</h2>
                     <h3 className="display-4 mb-10 px-xl-10">The service we offer is specifically designed to meet your needs.</h3>
                  </div>

               </div>
               <div className="position-relative">
                  <div className="shape rounded-circle bg-soft-blue rellax w-16 h-16" data-rellax-speed="1" style={ { bottom: "-0.5rem", right: "-2.2rem", zIndex: "0" } }></div>
                  <div className="shape bg-dot primary rellax w-16 h-17" data-rellax-speed="1" style={ { top: "-0.5rem", left: "-2.5rem", zIndex: "0" } }></div>
                  <div className="row gx-md-5 gy-5 text-center">
                     <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                           <div className="card-body">
                              <img src="./assets/img/icons/lineal/search-2.svg" className="svg-inject icon-svg icon-svg-md text-yellow mb-3" alt="" />
                              <h4>SEO Services</h4>
                              <p className="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                              <a href="!#" className="more hover link-yellow">Learn More</a>
                           </div>

                        </div>
                     </div>
                     <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                           <div className="card-body">
                              <img src="./assets/img/icons/lineal/browser.svg" className="svg-inject icon-svg icon-svg-md text-red mb-3" alt="" />
                              <h4>Web Design</h4>
                              <p className="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                              <a href="!#" className="more hover link-red">Learn More</a>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                           <div className="card-body">
                              <img src="./assets/img/icons/lineal/chat-2.svg" className="svg-inject icon-svg icon-svg-md text-green mb-3" alt="" />
                              <h4>Social Engagement</h4>
                              <p className="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                              <a href="!#" className="more hover link-green">Learn More</a>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                           <div className="card-body">
                              <img src="./assets/img/icons/lineal/megaphone.svg" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="" />
                              <h4>Content Marketing</h4>
                              <p className="mb-2">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                              <a href="!#" className="more hover link-blue">Learn More</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container pt-14 pt-md-16">
               {/*Empty section*/ }
            </div>
         </section>
      </>
   );
};

export default Home;
