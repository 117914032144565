import { PATIENTS_API_URL, SONAR_API_KEY } from "../helpers/env";
import { capitalize, getQueryParamsString, isSonarUrl } from "../helpers/utils";

const isValidPatientParams = (locationId, userId, patientId, token) => {
   if (!locationId || locationId.length <= 0) {
      return false;
   } else if (!userId || !userId.trim()) {
      return false;
   } else if (!patientId || !patientId.trim()) {
      return false;
   } else if (!token || !token.trim()) {
      return false;
   } else {
      return true;
   }
}

export const fetchPatient = async (locationId, userId, patientId, token) => {
   try {
      if (!isValidPatientParams(locationId, userId, patientId, token))
         return null;

      const url = `${process.env.REACT_APP_GATEWAY_API_BASE_URL}patients/getpatientbyid?locationid=${locationId}&userid=${userId}&id=${patientId}`;
      const fecthParams = {
         method: 'get',
         headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
         }
      }

      const response = await fetch(url, fecthParams);
      const patient = await response.json();

      if (!patient) {
         return null;
      }

      const name = patient.name[0];
      const family = capitalize(name.family);
      const firstName = capitalize(name.given[0]);
      const joinName = name.given.map(item => capitalize(item)).join(' ').trim();

      patient.id = userId;
      patient.shortName = firstName + ' ' + family;
      patient.fullName = (joinName + ' ' + family).trim();
      patient.initials = (joinName.charAt(0) + family.charAt(0)).trim();

      return patient;

   } catch (_) {
      return null;
   }
}

export const getPatientById = async (data, token) => {
   try {
      const queryString = getQueryParamsString(data)
      const url = `${PATIENTS_API_URL}/getPatientById?${queryString}`;
      const params = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json'
         }
      }

      if (token && isSonarUrl(url)) {
         params.headers["Authorization"] = `Bearer ${token}`;
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const foundPatient = await response.json();

      const id = data.Id;
      const name = foundPatient.name[0];
      const familyName = capitalize(name.family ?? '').trim();
      const firstName = capitalize(name.given[0] ?? '').trim();
      const givenName = name.given.map(item => capitalize(item)).join(' ').trim();
      const shortName = `${firstName} ${familyName}`;
      const fullName = `${givenName} ${familyName}`.trim();
      const initials = `${firstName.charAt(0)}${familyName.charAt(0)}`.trim();

      const patient = {
         id,
         firstName,
         familyName,
         givenName,
         shortName,
         fullName,
         initials,
      }

      return patient;
   }
   catch (error) {
      throw error;
   }
}