import { SONAR_API_KEY, VIDEOCHAT_API_URL } from "../helpers/env";
import { isSonarUrl } from "../helpers/utils";

export const getChannelByMeetingId = async (meetingId, token) => {
   try {
      const url = `${VIDEOCHAT_API_URL}/getChannelByMeetingId/${meetingId}`;
      const params = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json'
         }
      }

      if (token && isSonarUrl(url)) {
         params.headers["Authorization"] = `Bearer ${token}`;
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const channel = await response.json();

      if (!channel) {
         return null;
      }

      return channel
   }
   catch (error) {
      throw error;
   }
}

export const getChannelByShortenCode = async (shortenCode, token) => {
   try {
      const url = `${VIDEOCHAT_API_URL}/getChannelByShortenCode/${shortenCode}`;
      const params = {
         method: 'get',
         headers: {
            'Content-Type': 'application/json'
         }
      }

      if (token && isSonarUrl(url)) {
         params.headers["Authorization"] = `Bearer ${token}`;
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const channel = await response.json();

      if (!channel) {
         return null;
      }

      return channel
   }
   catch (error) {
      throw error;
   }
}

export const updatePatientJoin = async (data, token) => {
   try {
      const url = `${VIDEOCHAT_API_URL}/updatePatientJoin`;
      const params = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
      }

      if (token && isSonarUrl(url)) {
         params.headers["Authorization"] = `Bearer ${token}`;
         params.headers["x-api-key"] = SONAR_API_KEY;
      }

      const response = await fetch(url, params);
      const channel = await response.json();

      if (!channel) {
         return null;
      }

      return channel
   }
   catch (error) {
      throw error;
   }
}