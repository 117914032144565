import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallPlayer = () => {
   return (
      <>
         <div
            id='localPlayer'
            className={styles.localVideocall}
         />

         <div
            id='remotePlayer'
            className={`${styles.smallImage} rounded overflow-hidden`}
         />
      </>
   );
};

export default VideoCallPlayer;
