export const redirectUrl = '/';

const authEndPoint = 'https://www.sonarinformatics.com/sonar.auth0/';
const apiKey = '26637C29-C393-460A-9FFE-77C7EB0643D7';

const createCookie = (name, value, days) => {
   const options = {
      path: '/',
      samesite: 'strict'
      /*,
      secure: false*/
   };

   if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      options.expires = date.toUTCString();
   }

   let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
   for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
         updatedCookie += "=" + optionValue;
      }
   }
   document.cookie = updatedCookie;
};

const readCookie = (name) => {
   const nameEQ = name + "=";
   const ca = document.cookie.split(';');
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
   }
   return null;
};

const refreshToken = async (jwt) => {
   const t = jwt.Token.token.split(".");
   const payload = JSON.parse(atob(t[1]));
   const date = new Date(1000 * payload.exp);
   const today = new Date();

   if (date < today) {
      let url = authEndPoint + "refreshToken/" + jwt.Token.refreshToken;
      fetch(url, { headers: { 'x-api-key': apiKey } }).then(async (response) => {
         if (response.ok) {
            let r = await response.json();
            jwt.Token = r;
            createCookie("jwttoken", JSON.stringify(jwt));
         }
         else {
            // window.location.href = redirectUrl;
         }
      }).catch(function (err) {
         // window.location.href = redirectUrl;
      });
   }

   return jwt;
};

export const getToken = async (success) => {
   const cookie = readCookie("jwttoken");
   let jwt = {};

   if (cookie == null) {
      // window.location.href = redirectUrl;
   }
   else {
      jwt = JSON.parse(decodeURIComponent(cookie));
      jwt = await refreshToken(jwt);
   }

   if (success) {
      success(jwt);
   }

   return jwt;
};

export const getRoles = async () => {
   const cookie = readCookie("jwttoken");
   let jwt = {};

   if (cookie == null) {
      // window.location.href = redirectUrl;
   }
   else {
      jwt = JSON.parse(decodeURIComponent(cookie));
   }

   const t = jwt.Token.token.split(".");
   const payload = JSON.parse(atob(t[1]));

   var uid = payload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
   var lid = jwt.Claims?.find(e => e.Type === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality");
   var qid = jwt.Claims?.find(e => e.Type === "http://schemas.microsoft.com/ws/2008/06/identity/claims/version");

   var list = [];

   jwt.Claims?.forEach((i, v) => {
      if (v.Type === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") {
         list.push(v.Value);
      }
   });

   var obj = {
      "Roles": list,
      "UserId": uid,
      "LocationId": lid?.Value ?? 0,
      "QueueId": qid?.Value ?? 0,
   };

   return obj;
};
