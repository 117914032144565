import { useAuth } from '../../helpers/context/authContext';
import styles from '../../assets/css/video-call/styles.module.css'

const VideoCallUserInfo = () => {
   const { user, patient } = useAuth();

   return (
      <>
         <div className={styles.usersprof}>
            {patient && (
               <div className={`${styles.profile} bg-info border rounded`}>
                  <span className={styles.online} />
                  <span>
                     {patient.initials}
                  </span>
               </div>
            )}

            {user && (
               <div className={`${styles.profile} bg-info border rounded`}>
                  <div className={styles.online} />
                  <span>
                     {user.initials}
                  </span>
               </div>
            )}
         </div>

         <div className={`${styles.mediaBody} d-none d-sm-block`}>
            <h3>{user?.shortName}</h3>
         </div>
      </>
   );
};

export default VideoCallUserInfo;
