import { useAuth } from "../../helpers/context/authContext";
import VideoCallPlayer from './videoCallPlayer'
import VideoCallDetails from './videoCallDetails'
import VideoCallActions from './videoCallActions'
import VideoCallFailed from "./VideoCallFailed";
import { Progress } from "../../components/progress";
import { useVideoCall } from "../../helpers/context/videoCallContext";

const VideoCallContent = () => {
   const { user, patient, channel, failed } = useAuth()
   const { acceptTerms } = useVideoCall()

   if ((!user || !patient || !channel) && !failed) {
      return <Progress active={true} />
   }

   if (failed) {
      return (
         <VideoCallFailed
            message='Failed to fetch user or patient.'
         />
      )
   }

   if (!acceptTerms) {
      return (
         <VideoCallFailed
            message='You must accept the terms and conditions.'
         />
      )
   }

   return (
      <>
         <VideoCallPlayer />
         <VideoCallDetails />
         <VideoCallActions />
      </>

   );
};

export default VideoCallContent;
